import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import {
  CategoryListWithScrollCategoriesType,
  CategoryListWithScrollType,
} from '../../../../../../types/CategoryListWithScrollType';
import { CategoryListSelect } from './CategoryListSelect';
import { LinkField } from '../LatestArticleBlock';
import { CategoryListItem } from './CategoryListItem';
import { useGTMDataLayer } from '../../../../hooks/GTM/useGTMDataLayer';
import useSelector from '../../../../redux/typedHooks';
import { EventPayload } from '../../../../../../types/GTM/EventPayload';

export const CategoryListWithScrollBlock = ({ block }: { block: CategoryListWithScrollType }) => {
  const [selectedCategory, setSelectedCategory] = useState(block.categories[0]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  const [textRefPosition, setTextRefPosition] = useState<'top' | 'scroll' | 'bottom'>('top');
  const [topTextRect, setTopTextRect] = useState(textRef.current?.getBoundingClientRect());
  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (textRefPosition === 'top') {
        const listen = () => {
          if (!textRef.current) return;
          const textRect = textRef.current.getBoundingClientRect();
          setTopTextRect(textRect);
        };
        window.addEventListener('resize', listen);
        listen();
        return () => window.removeEventListener('resize', listen);
      }
      return undefined;
    }
    return undefined;
  }, [textRefPosition]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const listen = () => {
        if (!wrapperRef.current || !textRef.current) return;

        const textRect = textRef.current.getBoundingClientRect();
        const wrapperRect = wrapperRef.current.getBoundingClientRect();

        if (wrapperRect.top > -24) {
          setTextRefPosition('top');
        } else if (wrapperRect.bottom - textRect.height < -24) {
          setTextRefPosition('bottom');
        } else {
          setTextRefPosition('scroll');
        }
      };
      window.addEventListener('scroll', listen);
      return () => window.removeEventListener('scroll', listen);
    }
    return undefined;
  }, []);

  const textRefStyle = (): CSSProperties | undefined => {
    if (!topTextRect || !wrapperRef.current) return undefined;
    if (typeof window !== 'undefined' && window.innerWidth < parseFloat(breakpoints.m)) return undefined;

    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    if (textRefPosition === 'scroll') {
      return {
        position: 'fixed',
        left: topTextRect.left,
        top: 0,
        width: topTextRect.width,
      };
    }
    if (textRefPosition === 'bottom') {
      return {
        position: 'relative',
        transform: `translateY(${wrapperRect.height - topTextRect.height}px)`,
      };
    }
    return undefined;
  };

  const items = useMemo(
    () =>
      selectedCategory.elements.map((element) => (
        <CategoryListItem element={element} key={element.link.title || element.link.internalReference?.name} />
      )),
    [selectedCategory],
  );

  const handleSelect = (category: CategoryListWithScrollCategoriesType) => {
    if (dataLayer) {
      const eventPayload: EventPayload = {
        event: 'categoryListSelect',
        listValue: `${category.title}`,
        page: location.pathname,
        pageUrl: `${baseUrl}${location.pathname}`,
      };
      dataLayer.push(eventPayload);
    }
    setSelectedCategory(category);
  };

  return (
    <div>
      <LinkFieldWrapper>
        <LinkField link={selectedCategory.link} />
      </LinkFieldWrapper>
      <Wrapper>
        <TextWrapper>
          <TextContent ref={textRef} style={textRefStyle()}>
            <Text tag="span" variant={TextVariant.BlockquoteLarge} key={block.textBeforeCategories}>
              {block.textBeforeCategories}
            </Text>
            <CategoryListSelect selected={selectedCategory} options={block.categories} handleSelect={handleSelect} />
            <Text tag="span" variant={TextVariant.BlockquoteLarge} key={block.textAfterCategories}>
              {block.textAfterCategories}
            </Text>
          </TextContent>
        </TextWrapper>
        <CategoryContentWrapper>
          <ArticleWrapper>{items}</ArticleWrapper>
        </CategoryContentWrapper>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: -${spacing.space20} 0 0;
  gap: ${spacing.space20};

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const TextContent = styled.div`
  margin-right: ${spacing.space80};
  @media (max-width: ${breakpoints.m}) {
    padding: 0;
  }

  & > *:not(:last-child) {
    margin-right: ${spacing.space20};
  }
`;

const CategoryContentWrapper = styled.div`
  flex-basis: 50%;
  padding: ${spacing.space20} 0 0;
  display: flex;
  flex-direction: column;
  gap: ${spacing.space20};

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

const LinkFieldWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  padding-bottom: ${spacing.space20};
  @media (max-width: ${breakpoints.m}) {
    justify-content: flex-start;
    padding-bottom: 0;
    padding-top: ${spacing.space20};
  }
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space40};
`;
