import { fontStyle, themeLight } from '@naf/theme';
import React, { useEffect, useRef } from 'react';
import { Text, TextVariant } from '@naf/text';
import { ExpandMore } from '@styled-icons/material/ExpandMore';
import styled from 'styled-components';
import { CategoryListWithScrollCategoriesType } from '../../../../../../types/CategoryListWithScrollType';

interface DropdownProps {
  options: CategoryListWithScrollCategoriesType[];
  handleSelect: (option: CategoryListWithScrollCategoriesType) => void;
  selected: CategoryListWithScrollCategoriesType;
}

function renderOptions(recievedOptions: CategoryListWithScrollCategoriesType[]) {
  return recievedOptions.map(({ title }, i) => (
    <option value={i} key={title}>
      {title}
    </option>
  ));
}

export const CategoryListSelect = ({ options, handleSelect, selected }: DropdownProps) => {
  const selectedIndex = options.indexOf(selected);
  const selectRef = useRef<HTMLSelectElement>(null);

  const onSelect = (value: string) => {
    const selectedOption = options[parseInt(value, 10)];
    if (selectedOption) {
      handleSelect(selectedOption);
    } else {
      handleSelect(selected);
    }
  };

  useEffect(() => {
    if (selectRef.current) selectRef.current.selectedIndex = selectedIndex;
  }, [selectedIndex]);

  return (
    <Wrapper>
      <OverlayWrapper>
        <Overlay>
          <Text tag="span" variant={TextVariant.Header2}>
            {selected.title}
          </Text>
          <ExpandMore height={fontStyle.bodyText.small['font-size']} />
        </Overlay>
      </OverlayWrapper>
      <StyledSelect
        ref={selectRef}
        onChange={(e) => {
          onSelect(e.target.value);
          selectRef.current?.blur();
        }}
      >
        {renderOptions(options)}
      </StyledSelect>
    </Wrapper>
  );
};

const OverlayWrapper = styled.div`
  position: relative;
  z-index: 1;
  background: white;
  pointer-events: none;
`;

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
  box-sizing: border-box;
  border-bottom: 2px solid ${themeLight.componentColors.extras.accent};
`;

const StyledSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  box-sizing: border-box;

  & ${StyledSelect} {
    border: none;
    padding: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: 0;
    cursor: pointer;
  }

  &:focus-within {
    ${Overlay} {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;
