import React from 'react';
import styled from 'styled-components';
import { radius, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import { LinkWithImageType } from '../../../../../../types/CategoryListWithScrollType';
import { useCloudinary } from '../../../../hooks/Cloudinary/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { ExternalTarget, LinkType } from '../../../../../../types/LinkType';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { InternalLinkType } from '../../../../../../types/internalLinkType';
import { HoverWrap } from '../LatestArticleBlock/LatestArticleItem';
import { StyledLink } from '../../../styled-link/StyledLink';

export const CategoryListItem = ({ element }: { element: LinkWithImageType }) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const image = element.image || element.link.internalReference?.image;

  const cldImage = image?.publicId
    ? cld.image(image?.publicId).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto')
    : undefined;

  return (
    <Wrapper link={element.link}>
      <ImageContainer>
        {cldImage ? (
          <AdvancedImage
            style={{ maxWidth: '100%' }}
            alt={image?.altText || image?.alt}
            cldImg={cldImage}
            plugins={[
              lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
              responsive({ steps: 200 }),
              placeholder({ mode: 'blur' }),
            ]}
          />
        ) : null}
      </ImageContainer>
      <StyledText variant={TextVariant.CardHeader} tag="h3">
        <HoverWrap>{element.link.title || element.link.internalReference?.name}</HoverWrap>
      </StyledText>
      {element.link.internalReference?.ingress && <Ingress>{element.link.internalReference.ingress}</Ingress>}
    </Wrapper>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;
  background: lightgrey;

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: ${radius.s};
  }
`;

const StyledText = styled(Text)`
  margin: ${spacing.space12} 0;
`;

const Ingress = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Wrapper = styled(
  ({ link, children, className }: { link: LinkType; children: React.ReactNode; className?: string }) => {
    switch (link.targetType) {
      case 'internalReference':
        if (link.internalReference) {
          return (
            <InternalLink internalReference={link.internalReference} className={className}>
              {children}
            </InternalLink>
          );
        }
        return null;
      case 'externalTarget':
        if (link.externalTarget) {
          return (
            <ExternalLink externalTarget={link.externalTarget} className={className}>
              {children}
            </ExternalLink>
          );
        }
        return null;
      default:
        return null;
    }
  },
)`
  &:hover {
    ${HoverWrap} {
      background-size: 100% 2px;
    }
  }
`;

const ExternalLink = styled(
  ({
    externalTarget,
    children,
    className,
  }: {
    externalTarget: ExternalTarget;
    children: React.ReactNode;
    className?: string;
  }) => {
    const { href, blank } = externalTarget;
    if (blank) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
          {children}
        </a>
      );
    }
    return (
      <a href={href} className={className}>
        {children}
      </a>
    );
  },
)`
  text-decoration: none;
  :hover {
    color: inherit;
  }
`;

const InternalLink = styled(
  ({
    internalReference,
    children,
    className,
  }: {
    internalReference: InternalLinkType;
    children: React.ReactNode;
    className?: string;
  }) => {
    const target = useDocumentUrlWithNode(internalReference);
    if (!target) return null;
    return (
      <StyledLink to={target} className={className}>
        {children}
      </StyledLink>
    );
  },
)`
  text-decoration: none;
`;
